import { createApi } from '@reduxjs/toolkit/query/react';
import _ from 'lodash';
import { WorkspaceConnectionDetails, WorkspaceId, WorkspaceImage } from 'models/workspace';
import axiosBaseQuery from 'store/axiosBaseQuery';

const workspaceService = {
    getWorkspaceUrl: (workspaceId: WorkspaceId): string => {
        return `/workspace/${workspaceId.recruitmentId}/${workspaceId.sessionId}`;
    },

    getCandidateWorkspaceUrl: (workspaceId: WorkspaceId): string => {
        return `/workspace/candidate/${workspaceId.recruitmentId}/${workspaceId.sessionId}/${workspaceId.code}`;
    },

    openWorkspaceInNewTab: (workspaceId: WorkspaceId): void => {
        const win = window.open(workspaceService.getWorkspaceUrl(workspaceId), '_blank');
        if (win != null) {
            win.focus();
        }
    },

    getWorkspaceStreamUrl: (url: string, sessionId: string) => {
        return `wss://${url}/api/v1/vms/${sessionId}/graphics/stream`;
    }
};

export default workspaceService;

// ////

enum TagType {
    Workspaces = 'workspaces'
}

export const workspaceApiService = createApi({
    reducerPath: 'workspaceApiService',
    baseQuery: axiosBaseQuery({ basePath: '/recruitments' }),
    tagTypes: [TagType.Workspaces],
    endpoints: (builder) => ({
        // getWorkspace: builder.query<Workspace, string>({
        //     query: (workspaceId) => ({ basePathOverride: '/vms', url: `/one/${workspaceId}`, method: 'get' }),
        //     providesTags: (result, error, id) => [{ type: TagType.Workspaces, id }]
        // }),

        getImages: builder.query<WorkspaceImage[], string>({
            queryFn: (userEmail) => {
                let images: WorkspaceImage[] = [
                    { title: 'Ubuntu', name: 'ubuntu', software: ['Ubuntu 20.04', 'Google Chrome', 'Firefox', 'Slack'] },
                    // {
                    //     title: 'Ubuntu - Anaconda',
                    //     name: 'ubuntu-anaconda',
                    //     software: [
                    //         'Ubuntu 20.04',
                    //         'PyCharm Community 2023.2.3',
                    //         'Anaconda 3.26.1',
                    //         'Git 2.25.1',
                    //         'Google Chrome',
                    //         'Firefox'
                    //     ]
                    // },
                    {
                        title: 'Ubuntu - Engineer',
                        name: 'ubuntu-engineer',
                        software: [
                            'Ubuntu 20.04',
                            'Node.js 23.0.0',
                            'npm 10.9.0',
                            'CircleCI CLI 0.1.30995',
                            'AWS CLI 2.18.12',
                            'Terraform 1.9.8',
                            'Docker 27.3.1',
                            'TypeScript 5.6.3',
                            'Python 3.8.10',
                            'Apache Maven 3.6.3',
                            'Apache Kafka 3.8.1',
                            'PostgreSQL 12.20',
                            'Git 2.25.1',
                            'Visual Studio Code 1.94.2',
                            'Google Chrome',
                            'Firefox'
                        ]
                    },
                    {
                        title: 'Ubuntu - Node.js',
                        name: 'ubuntu-node',
                        software: [
                            'Ubuntu 20.04',
                            'Node.js 23.0.0',
                            'npm 10.9.0',
                            'CircleCI CLI 0.1.30995',
                            'AWS CLI 2.18.12',
                            'TypeScript 5.6.3',
                            'Git 2.25.1',
                            'Visual Studio Code 1.94.2',
                            'Google Chrome',
                            'Firefox'
                        ]
                    },
                    {
                        title: 'Ubuntu - Java',
                        name: 'ubuntu-java-1.1',
                        software: [
                            'Ubuntu 20.04',
                            'Java (OpenJDK 17.0.4)',
                            'IntelliJ IDEA Ultimate 2022.2.3',
                            'IntelliJ IDEA Community 2022.2.3',
                            'Visual Studio Code 1.72.2',
                            'Google Chrome',
                            'Firefox'
                        ]
                    },
                    {
                        title: 'Ubuntu - Java + Python',
                        name: 'ubuntu-java-python-1.0',
                        software: [
                            'Ubuntu 20.04',
                            'Java (OpenJDK 17.0.4)',
                            'Python (3.8.10, 3.9.5)',
                            'IntelliJ IDEA Ultimate 2022.2.3',
                            'IntelliJ IDEA Community 2022.2.3',
                            'PyCharm Professional 2022.2.3',
                            'PyCharm Community 2022.2.3',
                            'Visual Studio Code 1.72.2',
                            'Google Chrome',
                            'Firefox'
                        ] // {
                        //     title: 'Ubuntu - .NET',
                        //     name: 'ubuntu-dotnet-1.0',
                        //     software: ['Ubuntu 20.04', '.NET 6.0.402', 'Visual Studio Code 1.72.2']
                        // },
                    },
                    // {
                    //     title: 'Ubuntu - .NET',
                    //     name: 'ubuntu-dotnet-1.0',
                    //     software: ['Ubuntu 20.04', '.NET 6.0.402', 'Visual Studio Code 1.72.2']
                    // },
                    // {
                    //     title: 'Ubuntu - TypeScript',
                    //     name: 'ubuntu-tsc-1.0',
                    //     software: [
                    //         'Ubuntu 20.04',
                    //         'TypeScript 4.8.4',
                    //         'Node.js 19.0.0',
                    //         'WebStorm 2022.2.3',
                    //         'Visual Studio Code 1.72.2'
                    //     ]
                    // },
                    // {
                    //     title: 'Ubuntu - Ansible',
                    //     name: 'ubuntu-ansible-1.0',
                    //     software: [
                    //         'Ubuntu 20.04',
                    //         'IntelliJ IDEA Ultimate 2022.2.3',
                    //         'IntelliJ IDEA Community 2022.2.3',
                    //         'PostgreSQL 12.12',
                    //         'pgAdmin4 6.14',
                    //         'Ansible 2.13.4',
                    //         'Google Chrome',
                    //         'Firefox'
                    //     ]
                    // },
                    // {
                    //     title: 'Ubuntu - Ruby on Rails',
                    //     name: 'ubuntu-ruby-rails-1.0',
                    //     software: [
                    //         'Ubuntu 20.04',
                    //         'Ruby 3.1.2',
                    //         'Rails 7.0.4',
                    //         'RubyMine 2022.3.2',
                    //         'SQLite 3.31.1',
                    //         'SQLite Browser 3.11.2',
                    //         'Google Chrome',
                    //         'Firefox'
                    //     ]
                    // },
                    // {
                    //     title: 'Ubuntu - AWS',
                    //     name: 'ubuntu-aws-1.0',
                    //     software: [
                    //         'Ubuntu 20.04',
                    //         'Python 3.8.10',
                    //         'AWS CLI 2.10.4',
                    //         'Terraform 1.3.9',
                    //         'Docker 20.10.17',
                    //         'Git 2.25.1',
                    //         'PyCharm Professional 2022.3.2',
                    //         'PyCharm Community 2022.3.2',
                    //         'IntelliJ IDEA Community 2022.3.2',
                    //         'Visual Studio Code 1.75.1',
                    //         'Google Chrome',
                    //         'Firefox'
                    //     ]
                    // },
                    // {
                    //     title: 'Ubuntu - Microsoft SQL Server',
                    //     name: 'ubuntu-mssql-1.0',
                    //     software: [
                    //         'Ubuntu 20.04',
                    //         'Microsoft SQL Server Developer Edition 16.0.4003.1',
                    //         'Microsoft SQL Server Tools 17.10.1.1',
                    //         'Azure Data Studio 1.40.2',
                    //         'DataGrip 2022.3.3',
                    //         'Visual Studio Code 1.76.1 + SQL Server Extension',
                    //         'Git 2.25.1',
                    //         'Google Chrome',
                    //         'Firefox'
                    //     ]
                    // },
                    // {
                    //     title: 'Ubuntu - OpenShift',
                    //     name: 'ubuntu-openshift-1.0',
                    //     software: [
                    //         'Ubuntu 20.04',
                    //         'OpenShift CLI 4.12',
                    //         'Kubectl 4.5.7',
                    //         'Docker 23.0.3',
                    //         'Podman 3.4.2',
                    //         'Git 2.25.1',
                    //         'Google Chrome',
                    //         'Firefox'
                    //     ]
                    // },
                    // {
                    //     title: 'Ubuntu - PySpark',
                    //     name: 'ubuntu-pyspark-1.0',
                    //     software: [
                    //         'Ubuntu 20.04',
                    //         'PySpark 3.4.0',
                    //         'Anaconda3 2023.03-1',
                    //         'Python 3.10.9, 3.9.16',
                    //         'PyCharm Community 2023.1.1',
                    //         'Visual Studio Code 1.78.1',
                    //         'Google Chrome',
                    //         'Firefox'
                    //     ]
                    // },
                    {
                        title: 'Ubuntu - Vagrant',
                        name: 'ubuntu-vagrant-1.0',
                        software: [
                            'Ubuntu 20.04',
                            'Vagrant 2.3.4',
                            'VirtualBox 6.1',
                            'Postman 10.13.0',
                            'Docker 23.0.6',
                            'Python 3.8.10',
                            'Git 2.25.1',
                            'Visual Studio Code 1.78.2',
                            'wget, bat, ncdu, mc, jq, yq',
                            'htop, curl, telnet, vim, tree',
                            'Google Chrome',
                            'Firefox'
                        ]
                    },
                    {
                        title: 'Ubuntu - Oracle',
                        name: 'ubuntu-oracle',
                        software: [
                            'Ubuntu 20.04',
                            'Oracle Database Free 23c',
                            'SQL Developer 23.1.1',
                            'Google Chrome',
                            'Firefox',
                            'Slack'
                        ]
                    },
                    {
                        title: 'Ubuntu - Snowflake',
                        name: 'ubuntu-snowflake',
                        software: [
                            'Ubuntu 20.04',
                            'SnowSQL 1.2.31',
                            'Visual Studio Code 1.86.2',
                            'Postman 10.23.0',
                            'Google Chrome',
                            'Firefox',
                            'Slack'
                        ]
                    },
                    {
                        title: 'Ubuntu - .NET',
                        name: 'ubuntu-dotnet',
                        software: [
                            'Ubuntu 20.04',
                            '.NET 8.0.200',
                            'Rider 2023.3.3',
                            'Visual Studio Code 1.86.2',
                            'Google Chrome',
                            'Firefox',
                            'Slack'
                        ]
                    }
                    // {
                    //     title: 'Ubuntu - Enterprise Architect',
                    //     name: 'ubuntu-sparx-ea',
                    //     software: ['Ubuntu 20.04', 'Google Chrome', 'Firefox', 'Sparx Enterprise Architect 16.1']
                    // }
                ];

                if (userEmail === atob('bHVmdGhhbnNhQHRlYW1zaGFycS5jb20=')) {
                    // lh
                } else if (userEmail === atob('bS5rbHVjemV3c2thLmJhcmFuQGFjY2VudHVyZS5jb20=')) {
                    //acc
                    images.push({ title: 'Ubuntu - Python Interviews', name: 'ubuntu-nda-python-int-1.1', software: [] });
                } else if (
                    userEmail.endsWith(atob('QG5vcmRlYS5jb20=')) ||
                    (userEmail.startsWith(atob('dGVhbXNoYXJxLmNvdXJzZXMr')) && userEmail.endsWith(atob('QGdtYWlsLmNvbQ=='))) ||
                    (userEmail.startsWith(atob('aW50ZXJ2aWV3ZXI=')) && userEmail.endsWith(atob('QHRlYW1zaGFycS5jb20=')))
                ) {
                    images = [
                        { title: 'Ubuntu', name: 'ubuntu', software: ['Ubuntu 20.04', 'Google Chrome', 'Firefox', 'Slack'] },
                        {
                            title: 'Ubuntu - Python',
                            name: 'ubuntu-nda-python-1.5',
                            software: [
                                'Ubuntu 20.04',
                                'Python 3.11.2, 3.9.5, 3.8.10',
                                'Java (OpenJDK 17.0.6)',
                                'Robot Framework 6.0.2',
                                'Docker 20.10.17',
                                'Git 2.25.1',
                                'Jenkins 2.397',
                                'kafka 2.13-3.4.0',
                                'minikube 1.29.0',
                                'IntelliJ IDEA Community 2023.1',
                                'PyCharm Community 2023.1',
                                'Visual Studio Code 1.77.0',
                                'Google Chrome',
                                'Firefox'
                            ]
                        },
                        { title: 'Ubuntu - Python Interviews', name: 'ubuntu-nda-python-int-1.1', software: [] },
                        { title: 'Ubuntu - Java', name: 'ubuntu-nda-java-1.1', software: [] },
                        { title: 'Ubuntu - .NET', name: 'ubuntu-nda-dotnet-1.0', software: [] },
                        // {
                        //     title: 'Ubuntu - AWS',
                        //     name: 'ubuntu-aws-1.0',
                        //     software: [
                        //         'Ubuntu 20.04',
                        //         'Python 3.8.10',
                        //         'AWS CLI 2.10.4',
                        //         'Terraform 1.3.9',
                        //         'Docker 20.10.17',
                        //         'Git 2.25.1',
                        //         'PyCharm Professional 2022.3.2',
                        //         'PyCharm Community 2022.3.2',
                        //         'IntelliJ IDEA Community 2022.3.2',
                        //         'Visual Studio Code 1.75.1',
                        //         'Google Chrome',
                        //         'Firefox'
                        //     ]
                        // },
                        // {
                        //     title: 'Ubuntu - TypeScript',
                        //     name: 'ubuntu-tsc-1.0',
                        //     software: [
                        //         'Ubuntu 20.04',
                        //         'TypeScript 4.8.4',
                        //         'Node.js 19.0.0',
                        //         'WebStorm 2022.2.3',
                        //         'Visual Studio Code 1.72.2'
                        //     ]
                        // },
                        {
                            title: 'Ubuntu - TypeScript Interviews',
                            name: 'ubuntu-nda-tsc-int-1.1',
                            software: [
                                'Ubuntu 20.04',
                                'TypeScript 4.8.4',
                                'Node.js 19.9.0',
                                'WebStorm 2023.1.1',
                                'Visual Studio Code 1.78.0',
                                'Mobile Bank App'
                            ]
                        }
                    ];
                } else if (userEmail.endsWith(atob('QHN0eG5leHQucGw='))) {
                    images = [
                        { title: 'Ubuntu', name: 'ubuntu', software: ['Ubuntu 20.04', 'Google Chrome', 'Firefox', 'Slack'] },
                        { title: 'Ubuntu - STX', name: 'ubuntu-stx-1.0', software: [] }
                    ];
                } else if (
                    userEmail.endsWith(atob('QGNvbW1lcnpiYW5rLmNvbQ==')) ||
                    userEmail.endsWith(atob('Y29tbWVyekB0ZWFtc2hhcnEuY29t'))
                ) {
                    images = [
                        { title: 'Ubuntu', name: 'ubuntu', software: ['Ubuntu 20.04', 'Google Chrome', 'Firefox', 'Slack'] },
                        {
                            title: 'Ubuntu - CB - Java',
                            name: 'ubuntu-cb-java',
                            software: [
                                'Ubuntu 20.04',
                                'Java (OpenJDK 21.0.2)',
                                'IntelliJ IDEA Ultimate 2024.1',
                                'IntelliJ IDEA Community 2024.1',
                                'Visual Studio Code 1.88.1',
                                'PostgreSQL 12.12',
                                'pgAdmin4 8.5',
                                'Git 2.25.1',
                                'Apache Maven 3.6.3',
                                'Advanced REST Client 17.0.9',
                                'Google Chrome',
                                'Firefox'
                            ]
                        },
                        {
                            title: 'Ubuntu - CB - Oracle',
                            name: 'ubuntu-cb-oracle',
                            software: [
                                'Ubuntu 20.04',
                                'Oracle Database 21c Express Edition',
                                'Oracle SQL Developer 22.2.1',
                                'IntelliJ IDEA Community 2024.1',
                                'Java (OpenJDK 21.0.2)',
                                'Postman 10.24.16',
                                'Git 2.25.1',
                                'Apache Maven 3.6.3',
                                'Google Chrome',
                                'Firefox'
                            ]
                        }
                    ];
                } else if (userEmail.startsWith(atob('dHJlbmVyLnRlc3R1akA='))) {
                    images = [
                        {
                            title: 'Ubuntu - Cyber Security',
                            name: 'ubuntu-cyber-security',
                            software: [
                                'Ubuntu 20.04',
                                'VirtualBox 6.1',
                                'Windows 10 Pro',
                                'Kali Linux 2023.3',
                                'Metasploitable 2',
                                'Google Chrome',
                                'Firefox'
                            ]
                        }
                    ];
                }

                return { data: _.sortBy(images, ['title']) };
            }
        }),
        getWorkspaceConnectionDetails: builder.query<WorkspaceConnectionDetails, WorkspaceId>({
            query: ({ recruitmentId, sessionId }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}/vm/console/presigned/unlimited`,
                method: 'put'
            }),
            transformResponse: (response: WorkspaceConnectionDetails, meta, { sessionId }) => {
                return {
                    ...response,
                    sessionId
                };
            }
        }),
        getCandidateWorkspaceConnectionDetails: builder.query<WorkspaceConnectionDetails, WorkspaceId>({
            query: ({ recruitmentId, sessionId, code }) => ({
                basePathOverride: '/tenants',
                url: `/one/cmit/recruitments/one/${recruitmentId}/sessions/one/${sessionId}/vm/console/presigned/limited`,
                method: 'put',
                params: { code }
            }),
            transformResponse: (response: WorkspaceConnectionDetails, meta, { sessionId }) => {
                return {
                    ...response,
                    sessionId
                };
            }
        }),
        uploadRecruiterFileToWorkspace: builder.mutation<
            void,
            { id: WorkspaceId; file: File; onUploadProgress: (progressEvent: ProgressEvent) => void }
        >({
            query: ({ id, file, onUploadProgress }) => ({
                method: 'put',
                url: `/one/${id.recruitmentId}/sessions/one/${id.sessionId}/vm/files/stream`,
                headers: { 'Content-Type': file.type },
                params: {
                    path: `/home/student/Desktop/${file.name}`
                },
                data: file,
                onUploadProgress
            })
        })
    })
});

export const {
    useGetImagesQuery,
    // useGetWorkspaceQuery,
    // useGetWorkspaceConnectionDetailsQuery,
    useLazyGetWorkspaceConnectionDetailsQuery,
    useLazyGetCandidateWorkspaceConnectionDetailsQuery,
    useUploadRecruiterFileToWorkspaceMutation
} = workspaceApiService;
